import React from 'react';
import {Helmet} from 'react-helmet';
import HeadingBanner from '../Shared/HeadingBanner/HeadingBanner';
import EngagementModels from '../Home/engagement_models';
import GrowthStages from './GrowthStages';
import '../../assets/css/how.css';

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>How | Tintash</title>
    </Helmet>
    <div className="how-container">
      {/* Company Page Banner */}
      <div className="heading-banner-section">
        <HeadingBanner
          heading={
            <div>
              The magic of 10x Teams:{' '}
              <strong style={{color: '#1AA5F8'}}>
                skilled members, strong bonds, shared vision.
              </strong>
            </div>
          }
          description=""
          imageSrc={require('../../assets/images/company/topbanner-bg-2.webp').default}
        />
      </div>
      <section className="relationship-section">
        <div className="container p-0 m-0">
          <div className="row justify-content-center align-items-center p-0 m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <h1 className="heading-big">
                Prototyping <br />
                relationships for <br />
                success
              </h1>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <div className="why-build-description mt-3">
                <p className="paragraph">
                  To set our client relationships up for success, we prototype them to address any
                  issues and ensure compatibility. This is done by working on a small stand-alone
                  project for the client. Through this exercise, we consistently establish a solid
                  foundation of mutual trust, which is crucial for fostering a successful
                  partnership.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <GrowthStages />
      <EngagementModels />
      <section className="focus-section">
        <div className="container p-0 m-0">
          <div className="row justify-content-center align-items-center p-0 m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <h1 className="heading-big">
                Maniacal focus <br />
                on the right <br />
                problem to solve
              </h1>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <div className="why-build-description mt-3">
                <p className="paragraph-border">
                  Truly successful products happen when business, design and technology collaborate
                  from the very start, agree on the first problems to solve, and establish
                  discipline in addressing them.
                </p>
                <p className="paragraph">
                  Our teams prioritize understanding your problem, target audience, and motivations,
                  allowing us to identify the best product design and engineering solutions. This
                  approach allows for smarter problem-solving, scalability, testing, and progression
                  to the next level.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="how-we-succeed-section">
        <div className="row justify-content-center align-items-center how-we-succeed-container p-0 m-0">
          <div className="col-lg-6 col-sm-12 how-we-succeed-body">
            <h1 className="latoBlack text-left heading">How we succeed</h1>
            <div className="text-box">
              <h1 className="latoBlack text-left sub-heading">Team formations</h1>
              <p className="latoRegular text-left description">
                We think of the perfect product team as a star soccer squad. Each member brings
                their A-game, complementing and boosting one another to create an outstanding
                formation.
              </p>
            </div>

            <div className="text-box">
              <h1 className="latoBlack text-left sub-heading">Our process and playbook</h1>
              <p className="latoRegular text-left description">
                We bring together team members from all over the world, making it easy for us to
                embrace different working styles - on-site, remote, or hybrid. Our secret sauce?
                We're all about proactive communication. It keeps us connected and drives strong
                results.
              </p>
            </div>

            <div className="text-box">
              <h1 className="latoBlack text-left sub-heading">Remote & on-site</h1>
              <p className="latoRegular text-left description">
                Drawing on a decade of learnings from building products, we've developed a
                human-centric playbook to help guide our teams deliver better, faster results. This
                is a live document, continually evolving as we learn more.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 p-0">
            <img
              src={require('../../assets/images/how-page/how-we-succeed-featured-img.webp').default}
              alt="Our Story"
            />
          </div>
        </div>
      </section>
    </div>
  </>
);
