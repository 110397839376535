import React, {useState, useEffect} from 'react';

import '../../../assets/css/growth-stages.css';

const GROWTH_STAGES_DATA = {
  title: 'Budgets and timelines for successful products',
  description: (
    <div>
      Since 2008, we've worked with startups and businesses to bring their products to life.
      Typically, successful projects have the following timelines and budgets in given phases of
      their journey.
    </div>
  ),
  cards: [
    {
      phase: 'Project Phase',
      details: 'Phase Details',
      duration: 'Duration',
      budgets: 'Typical Budgets',
    },
    {
      phase: 'Discovery Phase',
      details:
        'Minimize product risk by scoping out the design and any technical requirements for the product',
      duration: '2 ~ 4 Weeks',
      budgets: <strong>USD 15K+</strong>,
    },
    {
      phase: 'Investor MVP',
      details: 'Create an initial MVP* enabling you to engage investors for funding',
      duration: ' 1 ~ 2 Months',
      budgets: <strong>USD 50K+</strong>,
    },
    {
      phase: 'Go-To Market MVP',
      details:
        'Create a market MVP to test with early adopters and get a sense for the product market fit',
      duration: '4 ~ 6 Months',
      budgets: <strong>USD 150K+</strong>,
    },
    {
      phase: 'Improve Product Market Fit',
      details: 'Incorporate feedback from early adopters',
      duration: '6 Months+',
      budgets: <strong>USD 200K +</strong>,
    },
    {
      phase: 'Long-term Goal',
      details: 'Grow your business',
      duration: 'Indefinite',
      budgets: (
        <>
          <div>
            <strong>Scalable* </strong>
            <br />A 6 person managed team has a monthly burn of approximately USD 45K
          </div>
        </>
      ),
    },
  ],
};

function GrowthStages() {
  const [data, setData] = useState(GROWTH_STAGES_DATA);
  useEffect(() => {
    const firstCard = document.querySelector('.card-container:first-child');

    if (firstCard) {
      firstCard.style.position = 'sticky';
      firstCard.style.left = '0';
      firstCard.style.zIndex = '1';
    }
  }, []);
  return (
    <section className="growth-stages-section pt-5 pb-5">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 p-0">
            <div className="heading latoBlack text-center">{data.title}</div>
            <p className="subheading mt-3 mb-5 text-center">{data.description}</p>
            {data && (
              <div className="m-0" key={data.cards.length}>
                <div className="growth-stages-container">
                  {data.cards.map((card, index) => (
                    <div className="card-container">
                      {index === 0 ? (
                        <>
                          <div
                            className="card-phase p-3"
                            style={{borderRight: '2px solid #e7e7e7', color: '#6B6363'}}
                          >
                            <p className="growth-stages_title m-0">{card.phase}</p>
                          </div>
                          <div
                            className="card-details p-3"
                            style={{borderRight: '2px solid #e7e7e7 ', color: '#6B6363'}}
                          >
                            <p className="growth-stages_title m-0">{card.details}</p>
                          </div>
                          <div
                            className="card-duration p-3"
                            style={{borderRight: '2px solid #e7e7e7', color: '#6B6363'}}
                          >
                            <p className="growth-stages_title m-0">{card.duration}</p>
                          </div>
                          <div
                            className="card-budgets p-3"
                            style={{borderRight: '2px solid #e7e7e7', color: '#6B6363'}}
                          >
                            <p className="growth-stages_title m-0">{card.budgets}</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="card-phase justify-content-center p-3">
                            <p className="growth-stages_title m-0">{card.phase}</p>
                          </div>
                          <div className="card-details justify-content-center p-3">
                            <p className="growth-stages_description m-0">{card.details}</p>
                          </div>
                          <div className="card-duration justify-content-center p-3">
                            <p className="growth-stages_description m-0">{card.duration}</p>
                          </div>
                          <div className="card-budgets justify-content-center p-3">
                            <p className="growth-stages_description m-0">{card.budgets}</p>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                <p className="subheading mt-3 text-center" style={{fontSize: '16px'}}>
                  *MVP - Minimum Viable Product
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default GrowthStages;
