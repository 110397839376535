import React from 'react';
import '../../assets/css/engagement-models.css';
import EngagementModelCard from './EngagementModel/EngagementModelCard';

export const ENGAGEMENT_MODELS_DATA = {
  title: 'How we engage',
  description:
    'Our extensive experience working with clients ranging from disruptive startups to Fortune 500 enterprises led us to develop different engagement models tailor-made to your needs.',
  cards: [
    {
      title: 'Discovery Workshop',
      description: 'Create Your Minimum Viable Product with our Dedicated Teams',
      color: '#0EC476',
      shadow: '#0EC47635',
      benefits: [
        {
          label: 'Idea validation',
        },
        {
          label: 'Feasibility, Risk and Technical Evaluation / Assessment',
        },
        {
          label: 'Cost and Time Estimation',
        },
        {
          label: 'Trial period for Team Fit',
          id: 'benefits-tooltip-1',
          tooltip:
            'We believe in long term work relationships. But we always start with a small working experiment to see if we are the right fit for a new client. Hence, each engagement model has this trial experimental period.',
        },
      ],
      suitableFor: [
        'Individuals/startups at the inception of their product development journey',
        'Individuals/companies looking to validate their product idea and define the scope of their product.',
      ],
    },
    {
      title: 'Agile Innovation Team',
      description: 'Fixed Cost Discovery Leading to an Agile Team',
      color: '#00C5E5',
      shadow: '#00C5E535',
      benefits: [
        {
          label: 'Fixed cost discovery',
        },
        {
          label: 'Dedicated agile team',
        },
        {
          label: 'Iterative sprint based development',
        },
        {
          label: 'Trial period for team fit',
          id: 'benefits-tooltip-2',
          tooltip:
            'We believe in long term work relationships. But we always start with a small working experiment to see if we are the right fit for a new client. Hence, each engagement model has this trial experimental period.',
        },
      ],
      suitableFor: ['Funded Startups', 'Organisations looking for fast innovation teams'],
    },
    {
      title: 'Skill Based Augmentation',
      description: 'Pick From a Wide Talent Pool to Build Teams With Specific Expertise',
      color: 'linear-gradient(207.5deg, #942B26 -147.63%, #EF5A62 100.91%)',
      shadow: '#942B2635',
      benefits: [
        {
          label: 'Wide talent pool of expertise',
        },
        {
          label: 'Easily scalable across projects',
        },
        {
          label: 'Management control of team',
        },
        {
          label: 'Trial period for team fit',
          id: 'benefits-tooltip-3',
          tooltip:
            'We believe in long term work relationships. But we always start with a small working experiment to see if we are the right fit for a new client. Hence, each engagement model has this trial experimental period.',
        },
      ],
      suitableFor: ['For teams which need access to a particular expertise for their projects'],
    },
  ],
};

export default ({parent}) => (
  <>
    <section className="engagement-section pt-5 pb-2">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12">
            <div className="heading latoBlack text-center mb-5">{ENGAGEMENT_MODELS_DATA.title}</div>
            {/* <p className="subheading latoLight mt-3 mb-5 text-center">
              {ENGAGEMENT_MODELS_DATA.description}
            </p> */}
            <div className="row">
              {ENGAGEMENT_MODELS_DATA.cards.map((card, i) => (
                <div key={i} className="card-container col-lg-4 col-md-6 col-sm-12 mb-5">
                  <EngagementModelCard {...card} />
                </div>
              ))}
            </div>
            {parent == 'Contact' ? (
              <div className="container footer-box">
                <div className="footer-box-heading">
                  Don’t know exactly which model suits your requirements?
                </div>
                <div className="footer-box-content">
                  Reach out to our customer success team at bd@tintash.com
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </section>
  </>
);
