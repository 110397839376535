import React from 'react';

function EngagementModelCard({title, benefits, suitableFor}) {
  return (
    <div className="engagement-model__card card h-100 bg-transparent">
      <div className="engagement-model__card-title latoBold mb-0 pt-4 pb-0 px-4 ">{title}</div>

      <div className="engagement-model__card-body card-body mt-0 py-3 px-4 ">
        <div className="engagement-model__key-benefits-title latoBold mb-2">Key Benefits</div>
        <div className="engagement-model__key-benefits-list latoRegular">
          {benefits.map((benefit, i) => (
            <div
              key={i}
              onMouseEnter={
                benefit.tooltip
                  ? () => (document.getElementById(benefit.id).style.display = 'block')
                  : undefined
              }
              onMouseLeave={
                benefit.tooltip
                  ? () => (document.getElementById(benefit.id).style.display = 'none')
                  : undefined
              }
            >
              <span className="engagement-model__key-benefit-label">{benefit.label}</span>

              {!!benefit.tooltip && (
                <>
                  <i className="ml-2 fa fa-question-circle" aria-hidden="true" />
                  <span className="popuptext" id={benefit.id}>
                    {benefit.tooltip}
                  </span>
                </>
              )}
              <hr />
            </div>
          ))}
        </div>
        <div className="pt-5 engagement-model__suitable-for-title latoBold mb-2">Suitable For</div>
        <div className="engagement-model__suitable-for-list latoRegular">
          {suitableFor.map((label, i) => (
            <div key={i}>
              <span>{label}</span>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default EngagementModelCard;
